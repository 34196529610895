import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonModule,
  CheckboxModule,
  LoadingModule,
  ModalModule,
  SelectModule,
  DatePickerModule,
  CardModule,
  SwitchModule,
} from '@dpdhl/angular-shared-ui';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TextComponent } from './components/primitives/text/text.component';
import { LineGraphGenericComponent } from './components/graph/line-graph-generic/line-graph-generic.component';
import { GraphLegendComponent } from './components/graph/graph-legend/graph-legend.component';
import { CardHeaderComponent } from './components/card-header/card-header.component';
import { GraphHeaderComponent } from './components/graph/graph-header/graph-header.component';
import { GraphDataProcessorService } from './services/graph-management/graph-data-processor.service';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { FacilityFilterComponent } from './components/facility-filter/facility-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapsOverviewComponent } from './components/maps-overview/maps-overview.component';
import { MapsModule } from '@dpdhl/iot-shared-ui';
import { ReportDownloadDialogComponent } from './components/report-download-dialog/report-download-dialog.component';
import { SortingConfigPipe } from './pipes/table-column-sorting-config.pipe';
import { TableModule } from '@dpdhl-iot/como-ui';
import { InventoryTrackingUsageOverviewService } from './services/usage-overview/inventory-tracking-usage-overview.service';
import { ViewConfigurationService } from './services/view-configuration/view-configuration.service';
import { BaseChartDirective } from 'ng2-charts';
import { GraphLegendShapeComponent } from './components/graph/graph-legend/graph-legend-shape/graph-legend-shape.component';
import { SelectOptionComponent } from './components/select-option/select-option.component';
import { AlertRuleListComponent } from './components/alert-rule-list/alert-rule-list.component';
import { CoMoDeviceManagementService } from './services/device-management/device-management.service';
import { CarouselComponent } from './components/carousel/carousel.component';
@NgModule({
  declarations: [
    TextComponent,
    CardHeaderComponent,
    GraphHeaderComponent,
    GraphLegendComponent,
    LineGraphGenericComponent,
    ConfirmationDialogComponent,
    FacilityFilterComponent,
    MapsOverviewComponent,
    ReportDownloadDialogComponent,
    SortingConfigPipe,
    GraphLegendShapeComponent,
    SelectOptionComponent,
    AlertRuleListComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    LoadingModule,
    AngularSvgIconModule,
    ButtonModule,
    ModalModule,
    CheckboxModule,
    TableModule,
    SelectModule,
    ReactiveFormsModule,
    FormsModule,
    DatePickerModule,
    MapsModule,
    CardModule,
    SwitchModule,
    CarouselComponent,
    BaseChartDirective,
  ],
  exports: [
    TextComponent,
    CardHeaderComponent,
    GraphHeaderComponent,
    GraphLegendComponent,
    GraphLegendShapeComponent,
    LineGraphGenericComponent,
    ConfirmationDialogComponent,
    FacilityFilterComponent,
    MapsOverviewComponent,
    ReportDownloadDialogComponent,
    SortingConfigPipe,
    SelectOptionComponent,
    AlertRuleListComponent,
    CarouselComponent
  ],
  providers: [
    GraphDataProcessorService,
    InventoryTrackingUsageOverviewService,
    ViewConfigurationService,
	CoMoDeviceManagementService
  ],
})
export class SharedModule {}
